@font-face {
  font-family: "Hubot Sans";
  src: url("Hubot-Sans.woff2") format("woff2 supports variations"),
    url("Hubot-Sans.woff2") format("woff2-variations");
  font-weight: 200 900;
  font-stretch: 75% 125%;
}
html {
  font-family: "Hubot Sans";
}

.hoverOpacity:hover {
  opacity: 0.8;
}
